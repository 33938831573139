<template>
  <div class="profile-notification max-width">
    <div v-if="items.length > 0">
      <b-card>
        
      </b-card>
      <v-list-item
        three-line
        class="notification"
        
        v-for="(item, index) in items"
        :key="index"
        @click="typeHandler(item)"
      >
      <div  v-if="item.read_at == 0 ? true : false"  style="width: 8px; height: 8px; background-color: #001489; position: absolute; right: 7px; top: 0px; border-radius: 50%;" ></div> 
        <v-list-item-avatar>
          <v-img
            :src="item.sender_imag == '#' ? $defaultImage : item.sender_imag"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="mx-2" >
          <v-list-item-title >{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle> {{ item.body }} </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="font-15 mx-2">
          {{ item.created_at.slice(0, 10) }}
        </v-list-item-action>
      </v-list-item>
      <!-- pagination -->
      <div class="max-width mt-10">
        <div class="d-flex justify-center">
          <v-pagination
            v-if="totalPages >= 1"
            v-model="page"
            :length="totalPages"
            @input="getData($event)"
            color="primary"
          ></v-pagination>
        </div>
      </div>
    </div>
    <no-items v-if="items.length == 0 && !loading" :title="$t('noNotification')"></no-items>
  <div v-if="loading" >
    <v-skeleton-loader v-for="n in 8" :key="n"
          type="image"
          class="mt-2"
          height="50px"
        ></v-skeleton-loader>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    items: [],
    page: 1,
    totalPages: 0,
    loading: false,
  }),
  computed:{
    ...mapGetters(["selectedIdChate"]),
  },
  methods: {
    async getData(number = 1) {
      this.loading = true;
      await this.$axios(`/getNotifications?page=${number}`).then((res) => {
        if (res.data) {
          this.items = res.data.data.notifications;
          this.page = number;
          this.totalPages = Math.ceil(res.data.data.total / res.data.data.per_page);
          this.loading = false;
        }
      });
    },
    typeHandler(item) {
      if (item.type == "message") {
        this.$router.push(`/profile/chat/${item.sender_id}`);
      }
      if (item.type == "bid shipment") {
        this.$router.push(`/myShipment/details/${item.object_id}`);
      }
      if (item.type == "bid product") {
        this.$router.push(`/myProduct/details/${item.object_id}`);
      }
      if (item.type == "completed order") {
        this.$router.push(`/profile/shipments`);
      }
    },
  },
  watch:{
    selectedIdChate(){
      this.getData()
    }
  },
  async created() {
    this.$store.commit("SET_ISNREAD",false)
    await this.getData();
  },
};
</script>

<style lang="scss">
.profile-notification {
  .v-list--three-line .v-list-item, .v-list-item--three-line {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .notification {
    border-bottom: 1px solid rgba($grey, 0.1);
  }
  .theme--light.v-skeleton-loader .v-skeleton-loader__bone{
    width: 100% !important;
    // height: 40px !important;
  }
  .v-skeleton-loader__actions{
    padding: 0px !important;
  }
}
</style>
